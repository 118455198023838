import { useCallback, useMemo } from 'react';
import {
  Checkbox,
  CheckBoxIcon,
  CheckBoxOutlineBlankIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mtb/ui';
import { useSessionStorage } from '../../../hooks';
import { useTranslation } from '../../../services/i18n';
import { useStorageExplorer } from '../hooks';

export const FILE_TYPE_FILTERS = [
  { label: 'fileTypeMinitabProject', filterValues: ['.mpx', '.mpj'] },
  { label: 'fileTypeMinitabWorksheet', filterValues: ['.mwx', '.mtw'] },
  { label: 'fileTypeText', filterValues: ['.txt'] },
  { label: 'fileTypeCSV', filterValues: ['.csv'] },
  { label: 'fileTypeData', filterValues: ['.dat'] },
  { label: 'fileTypeSpreadsheet', filterValues: ['.xlsx'] },
  { label: 'fileTypeMinitabScript', filterValues: ['.mac', '.mtb'] },
  { label: 'fileTypeWorkspaceProject', filterValues: ['.wspx', '.qcpx'] },
  { label: 'fileTypeBrainstormProject', filterValues: ['.mbpx'] },
  { label: 'fileTypeMinitabReport', filterValues: ['.mrpt'] },
];

export const StorageExplorerFileTypeFilter = () => {
  const [t, i18n] = useTranslation();
  const { id, defaultFilter, setFilter } = useStorageExplorer();
  const [selectedFilters, setSelectedFilters] = useSessionStorage({
    noun        : 'file-type-filter',
    id,
    initialValue: [],
  });

  const checkboxOptions = useMemo(
    () =>
      FILE_TYPE_FILTERS.filter(({ filterValues }) =>
        defaultFilter.some((f) => filterValues.includes(f)),
      ),
    [defaultFilter],
  );

  const handleOnInputChange = useCallback(
    ({ target: { value } }) => {
      let newSelectedFilters = value;
      const { label: newValueLabel } = value[value.length - 1];
      if (selectedFilters.some((f) => f.label === newValueLabel)) {
        // If the filter is already selected, remove it
        newSelectedFilters = selectedFilters.filter(
          (f) => f.label !== newValueLabel,
        );
      }
      if (!newSelectedFilters.length) {
        // If the last filter was unselected, set the default filter
        setSelectedFilters([]);
        setFilter(defaultFilter);
        return;
      }
      setSelectedFilters(newSelectedFilters);
      setFilter(newSelectedFilters.flatMap(({ filterValues }) => filterValues));
    },
    [setFilter, defaultFilter, selectedFilters, setSelectedFilters],
  );

  const handleRenderOption = useCallback(
    (option) => (
      <MenuItem
        key={option.label}
        value={option}>
        <Checkbox
          checked={selectedFilters.some((f) => f.label === option.label)}
          checkedIcon={<CheckBoxIcon />}
          icon={<CheckBoxOutlineBlankIcon />} />
        <ListItemText>{t(`connection.${option.label}`)}</ListItemText>
      </MenuItem>
    ),
    [t, selectedFilters],
  );

  const handleRenderValue = useCallback(
    (selectedOptions) => (
      <Typography
        color={selectedOptions.length === 0 ? 'text.disabled' : 'text.primary'}
        sx={{ display: 'inline-block' }}>
        {selectedOptions.length === 0 && t('connection.type')}
        {selectedOptions.length === 1 && i18n.localeSettings.listFormatter.format(selectedOptions[0].filterValues)}
        {selectedOptions.length > 1 && t('connection.moreSelected', { more: selectedOptions.length })}
      </Typography>
    ),
    [t, i18n.localeSettings.listFormatter],
  );

  return (
    <TextField
      data-testid="file-type-filter"
      select
      SelectProps={{
        multiple    : true,
        displayEmpty: true,
        value       : selectedFilters,
        renderValue : handleRenderValue,
        onChange    : handleOnInputChange,
      }}
      sx={{
        width          : '35%',
        minWidth       : 150,
        backgroundColor: (t) => t.palette.background['50'],
      }}>
      {checkboxOptions.map((option) => handleRenderOption(option))}
    </TextField>
  );
};
