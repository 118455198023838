import { useEffect, useMemo, useRef } from 'react';
import { Stack, Table, TableContainer, Typography, Fade } from '@mtb/ui';
import { useTranslation } from '../../../services/i18n';
import { useStorageExplorer, useTableSort } from '../hooks';
import { STORAGE_EXPLORER_COLUMNS, SKELETON_ITEMS } from './constants';
import { StorageExplorerTableContext } from './context';
import EmptyTableIcon from './EmptyTableIcon';
import { StorageExplorerTableBody } from './StorageExplorerTableBody';
import { StorageExplorerTableHead } from './StorageExplorerTableHead';

export const StorageExplorerTable = ({
  columns: columnsProp,
  children,
  ...other
}) => {
  const [t] = useTranslation();
  const tableContainerRef = useRef(null);
  const {
    items: storageItems,
    category,
    isLoading,
    dense,
    disableRowActions,
  } = useStorageExplorer();
  const columns = useMemo(
    () =>
      columnsProp && columnsProp.length
        ? columnsProp
        : [
          STORAGE_EXPLORER_COLUMNS.NAME,
          (category === 'recent'
            ? STORAGE_EXPLORER_COLUMNS.LAST_ACCESSED
            : STORAGE_EXPLORER_COLUMNS.LAST_MODIFIED
          ),
          STORAGE_EXPLORER_COLUMNS.OWNER,
          (!disableRowActions && STORAGE_EXPLORER_COLUMNS.ACTIONS),
        ],
    [columnsProp, category, disableRowActions],
  );
  const items = useMemo(
    () => (isLoading ? SKELETON_ITEMS : storageItems),
    [isLoading, storageItems],
  );
  const { rows, ascOrder, sortBy, toggleTableOrder, setTableSort } = useTableSort(items);
  const size = useMemo(() => (dense ? 'small' : 'medium'), [dense]);
  const hasItems = useMemo(() => !!items.length, [items.length]);

  useEffect(() => {
    setTableSort({ sortBy: category === 'recent' ? 'lastAccessed' : 'name' });
  }, [setTableSort, category]);

  const value = useMemo(
    () => ({
      isLoading,
      columns,
      tableContainerRef,
      rows,
      ascOrder,
      sortBy,
      toggleTableOrder,
      setTableSort,
    }),
    [ascOrder, columns, isLoading, rows, setTableSort, sortBy, toggleTableOrder],
  );

  return (
    <Stack
      sx={{
        height      : '100%',
        width       : '100%',
        overflow    : 'hidden',
        borderRadius: 1,
      }}>
      <TableContainer
        ref={tableContainerRef}
        sx={{
          flex     : '0 1 auto',
          height   : 'auto',
          overflowY: 'auto',
          overflowX: 'hidden',
          ...other.sx,
        }}
        {...other}>
        <Fade in>
          <Table
            data-testid="storage-explorer-table"
            size={size}
            stickyHeader
            sx={{ tableLayout: 'fixed' }}>
            <StorageExplorerTableContext.Provider value={value}>
              {!children && <StorageExplorerTableHead />}
              {(isLoading || (!isLoading && hasItems)) && (
                <>{children || <StorageExplorerTableBody />}</>
              )}
            </StorageExplorerTableContext.Provider>
          </Table>
        </Fade>
      </TableContainer>
      {!isLoading && !hasItems && (
        <Fade
          in
          // Increase the duration of the fade in animation to mask showing empty state
          // on the initial load of the table before we have a chance to fetch items.
          timeout={{ enter: 500 }}>
          <Stack
            align="center"
            backgroundColor="background.surface"
            sx={{ flex: '1 1 auto', pt: 7.5 }}>
            <Stack
              align="center"
              direction="row"
              justify="center"
              sx={{ width: 'min-content', height: 'min-content' }}>
              <EmptyTableIcon />
              <Typography
                sx={{ position: 'absolute', mt: '10%' }}
                variant="title-xl"
                weight="medium">
                {t('connection.noFilesFound')}
              </Typography>
            </Stack>
          </Stack>
        </Fade>
      )}
    </Stack>
  );
};
