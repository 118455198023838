import { STORAGE_PROVIDER_KEYS } from '../../constants';
import ProviderStore from '../../store/providers';
import { getItemTimestamps, getNameParts } from '../../utils';
import { ProviderBase } from '../ProviderBase';

const graphEndpoint = 'https://graph.microsoft.com/v1.0';

const dirTransform = ({ value }) => {
  return value.map(itemTransform);
};

/**
 * @returns {import('@').StorageProviderItem}
 */
const itemTransform = (item) => {
  const parentReference = item.parentReference || item.remoteItem?.parentReference || {};
  const root = Boolean(item.folder) && item.name === 'root';
  const nameParts = getNameParts(item.name);
  const timestamps = getItemTimestamps(item);
  const webUrl = item.listItem?.webUrl || item.webUrl || item.resourceReference?.webUrl;
  const isShared = parentReference.driveType === 'documentLibrary' || Boolean(item.remoteItem);

  return {
    type    : STORAGE_PROVIDER_KEYS.ONE_DRIVE,
    ...nameParts,
    ...timestamps,
    id      : item.id,
    driveId : parentReference.driveId,
    parentId: item.parentReference?.path?.endsWith('root:')
      ? 'root'
      : item.parentReference?.id || item.remoteItem?.parentReference?.id,
    ...((!Boolean(item.folder) && !isShared) && {
      parentFolderUrl: webUrl.replace(/\/[^/]+$/, ''),
    }),
    size           : item.size,
    folder         : Boolean(item.folder),
    createdDateTime: item.createdDateTime,
    createdBy      : {
      name:
        item.listItem?.createdBy?.user?.displayName ||
        item.createdBy?.user?.displayName,
    },
    parentReference: {
      id     : parentReference.id,
      driveId: parentReference.driveId,
      siteId : parentReference.siteId,
    },
    mimeType: item.file?.mimeType,
    root,
  };
};

export class OneDriveProviderAPI extends ProviderBase {
  /** @type {ProviderBase['getRootWebUrl']} */
  getRootWebUrl() {
    return 'https://www.onedrive.com';
  }

  /** @type {ProviderBase['_getRootInfo']} */
  async _getRootInfo() {
    const { webUrl } = await this.authorizedApiCall({
      url: `${graphEndpoint}/me/drive`,
    });
    return webUrl;
  }

  /** @type {ProviderBase['_getShareUrl']} */
  async _getShareUrl(id, driveId) {
    const { siteUrl, listId, listItemId } = await this.authorizedApiCall({
      url: `${graphEndpoint}/drives/${driveId}/items/${id}/sharepointIds`,
    });
    return `${siteUrl}/_layouts/15/sharedialog.aspx?listId={${listId}}&listItemId=${listItemId}&clientId=odb&ma=1`;
  }

  /** @type {ProviderBase['renameItem']} */
  renameItem(id, driveId, name) {
    return this.wrappedApiCall({
      url : `${graphEndpoint}/drives/${driveId}/items/${id}?@microsoft.graph.conflictBehavior=rename`,
      verb: 'PATCH',
      body: {
        name,
      },
      transform: (item) => item.name,
    });
  }

  /** @type {ProviderBase['getBreadcrumbs']} */
  async getBreadcrumbs(id, driveId) {
    let item = await this.getItemById(id, driveId);
    let done = item.parentId === 'root';
    const rootBreadcrumb = { id: 'root', webUrl: await this._getRootInfo() };
    const breadCrumbs = [];

    while (!done) {
      item = await this.getItemById(item.parentId, driveId);
      breadCrumbs.push(item);
      if (item.parentId === 'root' || item.error) {
        done = true;
        break;
      }
    }

    return [...breadCrumbs, rootBreadcrumb].reverse();
  }

  /** @type {ProviderBase['getFolderBreadcrumbs']} */
  async getFolderBreadcrumbs(id, driveId) {
    const rootBreadcrumb = { id: 'root', webUrl: await this._getRootInfo() };
    if (id === 'root') {
      return [rootBreadcrumb];
    }
    let item = await this.getItemById(id, driveId);
    const breadCrumbs = [item];
    while (item.parentId !== 'root') {
      item = await this.getItemById(item.parentId, driveId);
      breadCrumbs.push(item);
    }
    return [...breadCrumbs, rootBreadcrumb].reverse();
  }

  /** @type {ProviderBase['moveItem']} */
  async moveItem(id, driveId, parentId) {
    await this.wrappedApiCall({
      verb: 'PATCH',
      body: {
        parentReference: {
          id: parentId,
        },
      },
      url: `${graphEndpoint}/drives/${driveId}/items/${id}`,
    });
    return true;
  }

  /** @type {ProviderBase['createDefaultItem']} */
  createDefaultItem(name) {
    return this.wrappedApiCall({
      url: `${graphEndpoint}/me/drive/items/${
        this.getAutoSaveFolder().id
      }:/${encodeURIComponent(
        name,
      )}:/content?@microsoft.graph.conflictBehavior=rename&expand=listItem`,
      verb     : 'PUT',
      body     : {},
      transform: itemTransform,
    });
  }

  /** @type {ProviderBase['createInFolder']} */
  createInFolder(name, folder, conflictBehavior = 'rename') {
    return this.wrappedApiCall({
      url: `${graphEndpoint}/me/drive/items/${folder.id}:/${encodeURIComponent(
        name,
      )}:/content?@microsoft.graph.conflictBehavior=${conflictBehavior}&expand=listItem`,
      verb     : 'PUT',
      body     : {},
      transform: itemTransform,
    });
  }

  /** @type {ProviderBase['getAccount']} */
  getAccount(skipAuthRetry = false, auth = undefined) {
    return this.authorizedApiCall(
      {
        skipAuthRetry,
        url      : `${graphEndpoint}/me`,
        transform: (account) => ({
          name   : account.displayName,
          email  : account.mail,
          id     : account.id,
          picture: '',
        }),
      },
      auth,
    );
  }

  /** @type {ProviderBase['getAuthedAccount']} */
  getAuthedAccount() {
    return this.wrappedApiCall({
      url      : `${graphEndpoint}/me`,
      transform: (account) => ({
        name   : account.displayName,
        email  : account.mail,
        id     : account.id,
        picture: '',
      }),
    });
  }

  /** @type {ProviderBase['getAccountPicture']} */
  getAccountPicture() {
    return this.authorizedApiCall({
      url : `${graphEndpoint}/me/photo/$value`,
      type: 'blob',
    });
  }

  /** @type {ProviderBase['getInsightsSettings']} */
  getInsightsSettings() {
    return this.authorizedApiCall({
      url: `${graphEndpoint}/me/settings/itemInsights`,
    });
  }

  /** @type {ProviderBase['getAbout']} */
  getAbout(field = '*') {
    return this.wrappedApiCall({
      url      : `${graphEndpoint}/me/drive?select=${field}`,
      transform: (fields) => (field === '*' ? fields : fields[field]),
    });
  }

  /** @type {ProviderBase['getRootChildren']} */
  getRootChildren(signal) {
    return this.wrappedApiCall({
      url      : `${graphEndpoint}/me/drive/root/children?expand=listItem`,
      transform: dirTransform,
      signal,
    });
  }

  /**
   * @type {ProviderBase['getRecent']}
   */
  async getRecent(overrideFilter, signal) {
    const [response, rootResponse] = await Promise.all([
      this.makeApiCall({
        url: `${graphEndpoint}/me/drive/search(q='${
          overrideFilter ? overrideFilter.join(' OR ') : this.filter
        }')?orderby=lastModifiedDateTime%20desc&top=25`,
        signal,
      }),
      this.makeApiCall({
        url: `${graphEndpoint}/me/drive/root?select=id`,
        signal,
      }),
    ]);
    if (signal?.aborted) {
      return null;
    }
    const { id: rootItemId = '' } = rootResponse;
    if (response.error) {
      return response;
    }
    const recentFiles = (Array.isArray(response) ? response : response.value)
      .filter((item) => !item.folder)
      .map((item) => {
        // If the item doesn't have a parentReference or the parentReference doesn't
        // have a path, add one based on if the parent is the root. `itemTransform`
        // checks if the parentReference's path endsWith 'root:' to set the `parentId`
        item.parentReference ||= {};
        item.parentReference.path ||=
          rootItemId === item.parentReference.id ? 'root:' : '';
        const transformedItem = itemTransform(item);
        return transformedItem;
      });
    if (signal?.aborted) {
      return null;
    }
    // This search workaround to get recent files takes ~30sec or more to get an updated result
    // Filter or add recently deleted/duplicated files via sessionStorage
    const deletedFiles = JSON.parse(sessionStorage.getItem('deletedFiles'));
    const duplicatedFiles = JSON.parse(
      sessionStorage.getItem('duplicatedFiles'),
    );
    const recentItems = recentFiles.filter(
      (item) => !deletedFiles?.includes(item.id),
    );
    if (duplicatedFiles?.length) {
      duplicatedFiles.forEach((file) => {
        if (
          !recentItems.some((item) => item.id === file.id) &&
          !deletedFiles?.includes(file.id)
        ) {
          recentItems.push(file);
        }
      });
    }
    return recentItems;
  }

  /**
   * @type {ProviderBase['getNewRecent']}
   */
  async getNewRecent(overrideFilter, signal) {
    const isInsightsEnabled = ProviderStore.getProvider(STORAGE_PROVIDER_KEYS.ONE_DRIVE)?.account.isInsightsEnabled;
    if (!isInsightsEnabled) {
      // Add a fallback to the old recent items API if the new one isn't available
      const recentItemsResponse = await this.makeApiCall({
        url: `${graphEndpoint}/me/drive/recent?$top=500`,
        signal,
      });

      if (signal?.aborted) {
        return null;
      }

      const recentItems = (Array.isArray(recentItemsResponse) ? recentItemsResponse : recentItemsResponse.value)
        // Filter out deleted items
        .filter((item) => item.size > 0)
        .map((item) => {
          // If the item doesn't have a parentReference or the parentReference doesn't
          // have a path, add one based on if the parent is the root. `itemTransform`
          // checks if the parentReference's path endsWith 'root:' to set the `parentId`
          item.parentReference = item.remoteItem?.parentReference || item.parentReference || {};
          item.parentReference.path ||= '';
          item.createdBy ||= item.remoteItem?.shared?.sharedBy;
          const transformedItem = itemTransform(item);
          return transformedItem;
        });

      if (signal?.aborted) {
        return null;
      }

      return recentItems;
    }

    const types = [];
    const filterCopy = overrideFilter.slice();
    if (overrideFilter.includes('.xlsx')) {
      filterCopy.splice(filterCopy.indexOf('.xlsx'), 1);
      types.push('excel');
    }
    if (overrideFilter.includes('.csv')) {
      filterCopy.splice(filterCopy.indexOf('.csv'), 1);
      types.push('csv');
    }
    if (overrideFilter.includes('.txt')) {
      filterCopy.splice(filterCopy.indexOf('.txt'), 1);
      types.push('text');
    }
    if (filterCopy.includes('.mpx')) {
      filterCopy.splice(filterCopy.indexOf('.mpx'), 1);
      types.push('other');
    }
    if (filterCopy.length > 0) {
      // If we still have file type filters in the copy they are web files
      types.push('web');
    }

    const recentItems = [];
    let abortFetchingRecentItems = false;
    let nextPage = null;
    while (recentItems.length < 20 && !abortFetchingRecentItems) {
      // Make initial API call, then keep calling nextPage until we've got 20 items, or there's no more pages
      const recentlyUsedResponse = await this.makeApiCall({
        url: nextPage ?? `${graphEndpoint}/me/insights/used?$top=20&$filter=${types.map(type => `resourceVisualization/type eq '${type}'`).join(' or ')}`,
        signal,
      });

      if (signal?.aborted) {
        return null;
      }

      if (recentlyUsedResponse['@odata.nextLink']) {
        nextPage = recentlyUsedResponse['@odata.nextLink'];
      } else {
        abortFetchingRecentItems = true;
      }

      const filteredDriveItems = (
        Array.isArray(recentlyUsedResponse) ? recentlyUsedResponse : recentlyUsedResponse.value
      ).filter(item =>
        overrideFilter.some(value => {
          const url = item.resourceReference.webUrl;
          if (url) {
            const fileName = new URL(url).searchParams.get('file');
            return fileName?.endsWith(value) || url.endsWith(value);
          }
          return false;
        }),
      );
      recentItems.push(...filteredDriveItems);
    }

    if (recentItems.length === 0) {
      // If we're here they somehow have no recent items supported by Platform
      return null;
    }

    // The while loop above can return up to 39 items (19 on first loop + 20 on second), so we need to make request for all of them to avoid losing items from pages if we implement lazy loading
    // Chunk into groups of 20 because batch only supports 20 requests
    const chunkSize = 20;
    const chunkedRecentItems = recentItems.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

    const batchedItemsReponses = await Promise.all(chunkedRecentItems.map((chunk) => (
      this.wrappedApiCall({
        url : `${graphEndpoint}/$batch`,
        verb: 'POST',
        body: {
          requests: chunk.map(({ id, resourceReference }) => ({
            id,
            method: 'GET',
            url   : resourceReference.id,
          })),
        },
        signal,
      })
    )));

    if (signal?.aborted) {
      return null;
    }

    // Get a map of "id: { ...item }" for the recent items to get the lastUsed data from the `me/insights/used` endpoint
    const reducedRecentDriveItems = recentItems.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});

    const recentFiles = batchedItemsReponses
      .flatMap(({ responses }) => responses)
      .filter(item => item.status === 200)
      .map(({ id, body: item }) => {
      // If the item doesn't have a parentReference or the parentReference doesn't
      // have a path, add one based on if the parent is the root. `itemTransform`
      // checks if the parentReference's path endsWith 'root:' to set the `parentId`
        item.lastUsed = reducedRecentDriveItems[id].lastUsed;
        item.parentReference = item.remoteItem?.parentReference || item.parentReference || {};
        item.parentReference.path ||= '';
        item.createdBy ||= item.remoteItem?.shared?.sharedBy;
        const transformedItem = itemTransform(item);
        return transformedItem;
      });

    if (signal?.aborted) {
      return null;
    }

    return recentFiles;
  }

  /** @type {ProviderBase['getShared']} */
  async getShared(signal) {
    const response = await this.makeApiCall({
      url: `${graphEndpoint}/me/drive/sharedWithMe`,
      signal,
    });
    if (signal?.aborted) {
      return null;
    }
    const sharedFiles = (
      Array.isArray(response) ? response : response.value
    ).map((item) => {
      // If the item doesn't have a parentReference or the parentReference doesn't
      // have a path, add one based on if the parent is the root. `itemTransform`
      // checks if the parentReference's path endsWith 'root:' to set the `parentId`
      item.parentReference =
        item.remoteItem?.parentReference || item.parentReference || {};
      item.parentReference.path ||= '';
      item.createdBy ||= item.remoteItem?.shared?.sharedBy;
      const transformedItem = itemTransform(item);
      return transformedItem;
    });
    if (signal?.aborted) {
      return null;
    }
    return sharedFiles;
  }

  /** @type {ProviderBase['getFolderChildren']} */
  getFolderChildren(folderId, driveId, signal) {
    return this.wrappedApiCall({
      url      : `${graphEndpoint}/drives/${driveId}/items/${folderId}/children?expand=listItem`,
      transform: dirTransform,
      signal,
    });
  }

  /** @type {ProviderBase['getItemById']} */
  getItemById(id, driveId, useCache = true, params = '?expand=listItem') {
    return this.wrappedApiCall({
      url      : `${graphEndpoint}/drives/${driveId}/items/${id}${params}`,
      transform: itemTransform,
      cacheId  : useCache && id,
    });
  }

  /** @type {ProviderBase['searchItem']} */
  searchItem(text, pageSize = 25, options = '') {
    return this.wrappedApiCall({
      url      : `${graphEndpoint}/me/drive/search(q='${text}')?$top=${pageSize}&${options}`,
      transform: dirTransform,
    });
  }

  /** @type {ProviderBase['getUser']} */
  getUser(principalName, params = '') {
    return this.wrappedApiCall({
      url: `${graphEndpoint}/users/${principalName}?${params}`,
    });
  }

  /** @type {ProviderBase['getUserPhoto']} */
  getUserPhoto(principalName) {
    return this.wrappedApiCall({
      url : `${graphEndpoint}/users/${principalName}/photo/$value`,
      type: 'blob',
    });
  }

  /** @type {ProviderBase['getCheckoutUser']} */
  async getCheckoutUser({ id, driveId }) {
    if (!id || !driveId) {
      return undefined;
    }

    try {
      const { publication } = await this.makeApiCall({
        url: `${graphEndpoint}/drives/${driveId}/items/${id}?select=publication`,
      });

      if (!publication?.checkedOutBy) {
        return undefined;
      }

      const user = await this.getUser(publication.checkedOutBy.user.id);
      const pictureBinary = await this.getUserPhoto(
        publication.checkedOutBy.user.id,
      );
      return {
        ...user,
        email: user.mail,
        photo: pictureBinary && URL.createObjectURL(pictureBinary),
      };
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(error);
      }
      return undefined;
    }
  }

  /** @type {ProviderBase['duplicateItem']} */
  async duplicateItem(item, name) {
    const response = await this.authorizedApiCall({
      url : `${graphEndpoint}/drives/${item.driveId}/items/${item.id}/copy?@microsoft.graph.conflictBehavior=rename`,
      verb: 'POST',
      body: {
        parentReference: item.parentReference,
        name           : name ?? item.name,
      },
    });
    if (response.error) {
      return response;
    }
    const location = response.headers.get('location');
    let progress;
    do {
      progress = await fetch(location).then((response) => {
        return response.json();
      });
    } while (!progress?.resourceId);
    const duplicateItem = await this.getItemById(
      progress.resourceId,
      item.driveId,
    );

    if (item.parentId) {
      // Save duplicated file in session storage to add to files list immediately after refresh
      const duplicatedFiles = JSON.parse(
        sessionStorage.getItem('duplicatedFiles'),
      );
      sessionStorage.setItem(
        'duplicatedFiles',
        JSON.stringify(
          duplicatedFiles
            ? [...duplicatedFiles, duplicateItem]
            : [duplicateItem],
        ),
      );
    }
    return duplicateItem;
  }

  /** @type {ProviderBase['deleteItem']} */
  deleteItem(item) {
    // Save deleted file in session storage to filter from file list after refresh
    const deletedFiles = JSON.parse(sessionStorage.getItem('deletedFiles'));
    sessionStorage.setItem(
      'deletedFiles',
      JSON.stringify(deletedFiles ? [...deletedFiles, item.id] : [item.id]),
    );
    return this.wrappedApiCall({
      url : `${graphEndpoint}/drives/${item.driveId}/items/${item.id}`,
      verb: 'DELETE',
    });
  }

  /** @type {ProviderBase['checkInItem']} */
  checkInItem(item) {
    return this.wrappedApiCall({
      url : `${graphEndpoint}/drives/${item.driveId}/items/${item.id}/checkin`,
      verb: 'POST',
    });
  }

  /** @type {ProviderBase['getRemainingStorageSpace']} */
  async getRemainingStorageSpace() {
    const storage = await this.getAbout();
    return storage.quota?.remaining;
  }

  async getOneDriveItemFromShareURL(url) {
    return await this.wrappedApiCall({ url, transform: itemTransform });
  }

  /**
   * Downloads a file from OneDrive and returns a Blob.
   * @param {import('@').StorageProviderItem} item
   * @returns {Promise<Blob>}
   */
  async downloadItem(item) {
    return await this.wrappedApiCall({
      url : `${graphEndpoint}/drives/${item.driveId}/items/${item.id}/content`,
      type: 'blob',
      verb: 'GET',
    });
  }
}
